<template>
  <div>
    <label for="language">
      Choose language:
      <select class="p-2 outline-none" v-model="lang" name="language">
        <option value="en">English</option>
        <option value="az">Azerbaijani</option>
      </select>
    </label>
    <form @submit="validate" class="flex flex-wrap p-10">
      <h1 class="text-2xl mb-8">
        Send Mail <span class="text-green-500">{{ sent }}</span>
      </h1>
      <label for="toEmail" class="w-full flex flex-wrap mb-6">
        <span class="w-full"> To Email </span>
        <input
          v-model="formValues.to"
          class="border-b border-gray-400 outline-none px-3 w-72"
          type="email"
          name="toEmail"
        />
      </label>
      <label for="emailTitle" class="w-full flex flex-wrap mb-6">
        <span class="w-full"> Title </span>
        <input
          v-model="formValues.title"
          class="border-b border-gray-400 outline-none px-3 w-72"
          type="text"
          name="emailTitle"
        />
      </label>
      <label for="content" class="w-full flex flex-wrap mb-6">
        <span class="w-full"> Content </span>
        <textarea
          v-model="formValues.content"
          class="
            border-b border-gray-400
            py-2
            px-3
            w-full
            outline-none
            bg-gray-50
          "
          rows="10"
          name="content"
        />
      </label>
      <button
        class="
          rounded-lg
          bg-green-400
          hover:bg-green-500
          p-2
          pr-3
          cursor-pointer
          text-white
        "
        type="submit"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import requests from '../services/requests/mailRequests'
export default {
  name: 'SurveyForm',
  data () {
    return {
      lang: 'en',
      formValues: {
        to: '',
        title: '',
        content: ''
      },
      error: false,
      sent: ''
    }
  },
  methods: {
    validate (e) {
      e.preventDefault()
      if (
        this.formValues.to &&
        this.formValues.title &&
        this.formValues.content
      ) {
        this.handleSubmit()
      } else {
        this.error = true
      }
    },
    async handleSubmit () {
      await requests
        .createUser(this.formValues.to)
        .then((res) => {
          requests
            .sendSurvey({
              ...this.formValues,
              lang: this.lang,
              id: res.data
            })
            .then((res) => {
              this.formValues.to = ''
              this.sent = ' - SENT!'
            })
            .catch((err) => console.log(err))
        })
        .catch((err) => console.log(err))
    }
  }
}
</script>
